<template>
  <CRow>
    <CCol col="12" xl="12">
      <Spinner v-if="$store.state.loadSpinner" />

      <CCard v-else>
        <CCardHeader>
          <span>الادارة </span>
          <CButton
            v-if="can('dashboard.admins.store')"
            class="float-right"
            color="info"
            @click="
              $router.push({
                name: `Create Admin`,
              })
            "
          >
            <CIcon name="cil-plus"></CIcon>
            <span> اضافة اداري </span>
          </CButton>
        </CCardHeader>
        <CCardBody>
          <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover mb-5">
              <thead>
                <tr class="table-primary">
                  <th scope="col">#</th>
                  <th scope="col">الاسم</th>
                  <th scope="col">الموبايل</th>
                  <th scope="col">الصلاحيات</th>
                  <th scope="col">الاجراءات</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <th scope="row">{{ item.id }}</th>
                  <td>{{ item.name }}</td>
                  <td>{{ item.phone }}</td>
                  <td>
                    <span
                      v-for="(role, index) in item.roles"
                      :key="index"
                      class="badge badge-primary mx-1"
                    >
                      {{ role.name }}
                    </span>
                  </td>
                  <td>
                    <CButton
                      v-if="can('dashboard.admins.update')"
                      class="mx-1"
                      color="warning"
                      @click="openModal(item)"
                    >
                      <CIcon name="cil-pencil"></CIcon>
                    </CButton>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <CPagination :active-page.sync="currentPage" :pages="totalPages" />
        </CCardBody>
      </CCard>
    </CCol>
    <edit :form="modalForm" :roles="roles" @updateDone="updateDone"></edit>
  </CRow>
</template>

<script>
import Edit from "./Edit";
export default {
  name: "Admins",
  components: {
    Edit,
  },
  data() {
    return {
      items: [],
      roles: [],
      activePage: 1,
      currentPage: 1,
      totalPages: 0,
      allPages: 10,
      modalForm: null,
    };
  },
  watch: {
    currentPage: {
      handler: function (val) {
        this.fetchData(val);
      },
      deep: true,
    },
  },
  created() {
    this.fetchData();
    this.fetchRoles();
  },
  methods: {
    fetchData(page = 1) {
      this.toggleLoadSpinner();

      this.$http
        .get(`/admins/list-all?per_page=15&page=${page}`)
        .then((res) => {
          // handle success
          this.totalPages = res.data.last_page;
          this.items = res.data.data.data;
          this.totalPages = res.data.data.last_page;
          this.toggleLoadSpinner();
        })
        .catch(() => {
          //
          this.toggleLoadSpinner();
        });
    },

    fetchRoles() {
      this.$http.get(`/roles`).then((res) => {
        this.roles = res.data.data;
      });
    },

    toggleLoadSpinner() {
      this.$store.commit("toggleLoadSpinner");
    },

    stopAdmin(itemId) {
      console.log(itemId);
    },

    getBadge(status) {
      switch (status) {
        case 1:
          return "success";
        case 0:
          return "danger";
        default:
          "primary";
      }
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
    openModal(item) {
      this.modalForm = Object.assign({}, item);
      this.$store.commit("set", ["showModal", true]);
    },
    updateDone() {
      this.fetchData();
    },
  },
};
</script>